import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import "./scss/style.scss";

const getIsLoggedIn = () => {
  const accessToken = localStorage.getItem("accessToken");

  if (
    !accessToken ||
    accessToken === "undefined" ||
    accessToken === undefined
  ) {
    return false;
  }
  return true;
};

const requireLogin = (to, from, next) => {
  if (getIsLoggedIn()) {
    next();
  } else {
    next.redirect("/login");
  }
};

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ConfirmSupplierEmail = React.lazy(() =>
  import("./views/pages/supplier-confirm-email/supplier-confirm-email")
);
const Register = React.lazy(() => import("./views/pages/register/Register"));
const SupplierRegister = React.lazy(() =>
  import("./views/pages/supplierRegister/supplierRegister")
);
const CreateSupplierOrder = React.lazy(() =>
  import("./views/pages/create-supplier-order/create-supplier-order")
);
const CreateProject = React.lazy(() =>
  import("./views/pages/createProject/createProject")
);
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <GuardProvider guards={[requireLogin]}>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Inicio de Sesión"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/supplier-confirm-email/:id"
                name="Confirmar email Proveedor"
                render={(props) => <ConfirmSupplierEmail {...props} />}
              />
              <Route
                exact
                path="/create-project"
                name="Creación de Producto"
                render={(props) => <CreateProject {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Crear Cuenta"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/supplier-register"
                name="Crear Cuenta"
                render={(props) => <SupplierRegister {...props} />}
              />
              <Route
                exact
                path="/create-supplier-order/:project/:supplier"
                name="Crear Cotización"
                render={(props) => <CreateSupplierOrder {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <GuardedRoute
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </GuardProvider>
      </HashRouter>
    );
  }
}

export default App;
